/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Elements
import HeroDefault from 'elements/Hero/HeroDefault'
import Breadcrumb from 'elements/Breadcrumb'

// Components
import SEO from 'shared/SEO'
import Layout from 'layout/Layout'
import ParseContent from 'shared/ParseContent'

// Third Party
import styled from 'styled-components'
import GravityForm from 'components/shared/GravityForm'

const Row = styled.div``

const Content = styled(ParseContent)``

const FormWrapper = styled.div`
  background-color: ${(props) => props.theme.color.face.main};
  padding: 30px 40px 20px 40px;

  h2 {
    font-size: ${(props) => props.theme.font.size.lm};
    font-weight: ${(props) => props.theme.font.weight.xl};
    color: ${(props) => props.theme.color.text.light};
  }
`

const PageTemplate = ({
  data: {
    page: { title, seo, paginaKlacht },
  },
  pageContext,
}) => {
  return (
    <Layout>
      <SEO seo={seo} />

      <HeroDefault fields={paginaKlacht.paginaprivacybanner} />

      <Breadcrumb data={title} className="py-2" pageContext={pageContext} />

      <div className="container py-lg-5">
        <Row className="row justify-content-center">
          <div className="col-lg-9">
            <Content content={paginaKlacht.paginaprivacycontent.story} />
          </div>
        </Row>
        <Row className="row justify-content-center">
          <div className="col-lg-9">
            <FormWrapper>
              <h2 className="mb-4 pb-3">Stuur ons een bericht</h2>
              <GravityForm id={3} />
            </FormWrapper>
          </div>
        </Row>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($wordpress_id: Int) {
    page: wpPage(databaseId: { eq: $wordpress_id }) {
      ...KlachtPageFrag
    }
  }
`

export default PageTemplate
